import React from 'react';
import { Doughnut, Chart } from 'react-chartjs-2';

function RadialChart(props) {
	const { transactionData } = props;
	const value = transactionData.map((data) => data.TotalCost);
	const key = transactionData.map((data) => data.Symbol);

	const sumValue = value.reduce(function (a, b) {
		return a + b;
	}, 0);

	function numberFormat(num) {
		var num_parts = num.toString().split('.');
		num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		return num_parts.join('.');
	}

	const chartText =
		numberFormat(Math.round((sumValue + Number.EPSILON) * 100) / 100) + ' USD';

	Chart.pluginService.register({
		beforeDraw: function (chart) {
			if (chart.config.options.elements.center) {
				// Get ctx from string
				var ctx = chart.chart.ctx;

				// Get options from the center object in options
				var centerConfig = chart.config.options.elements.center;
				var fontStyle = centerConfig.fontStyle || 'Arial';
				var txt = centerConfig.text;
				var color = centerConfig.color || '#000';
				var maxFontSize = centerConfig.maxFontSize || 75;
				var sidePadding = centerConfig.sidePadding || 20;
				var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
				// Start with a base font of 30px
				ctx.font = '30px ' + fontStyle;

				// Get the width of the string and also the width of the element minus 10 to give it 5px side padding
				var stringWidth = ctx.measureText(txt).width;
				var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

				// Find out how much the font can grow in width.
				var widthRatio = elementWidth / stringWidth;
				var newFontSize = Math.floor(30 * widthRatio);
				var elementHeight = chart.innerRadius * 2;

				// Pick a new font size so it will not be larger than the height of label.
				var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
				var minFontSize = centerConfig.minFontSize;
				var lineHeight = centerConfig.lineHeight || 25;
				var wrapText = false;

				if (minFontSize === undefined) {
					minFontSize = 20;
				}

				if (minFontSize && fontSizeToUse < minFontSize) {
					fontSizeToUse = minFontSize;
					wrapText = true;
				}

				// Set font settings to draw it correctly.
				ctx.textAlign = 'center';
				ctx.textBaseline = 'middle';
				var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
				var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
				ctx.font = fontSizeToUse + 'px ' + fontStyle;
				ctx.fillStyle = color;

				if (!wrapText) {
					ctx.fillText(txt, centerX, centerY);
					return;
				}

				var words = txt.split(' ');
				var line = '';
				var lines = [];

				// Break words up into multiple lines if necessary
				for (var n = 0; n < words.length; n++) {
					var testLine = line + words[n] + ' ';
					var metrics = ctx.measureText(testLine);
					var testWidth = metrics.width;
					if (testWidth > elementWidth && n > 0) {
						lines.push(line);
						line = words[n] + ' ';
					} else {
						line = testLine;
					}
				}

				// Move the center up depending on line height and number of lines
				centerY -= (lines.length / 2) * lineHeight;

				// eslint-disable-next-line no-redeclare
				for (var n = 0; n < lines.length; n++) {
					ctx.fillText(lines[n], centerX, centerY);
					centerY += lineHeight;
				}
				//Draw text in center
				ctx.fillText(line, centerX, centerY);
			}
		},
	});

	const config = {
		data: {
			datasets: [
				{
					data: value,
					backgroundColor: [
						'#a9d6e5',
						'#89c2d9',
						'#61a5c2',
						'#468faf',
						'#2c7da0',
						'#2a6f97',
						'#014f86',
						'#01497c',
						'#013a63',
						'#012a4a',
					],
				},
			],
			labels: key,
		},
		options: {
			cutoutPercentage: 70,
			maintainAspectRatio: false,

			legend: {
				position: 'bottom',
				labels: {
					fontColor: 'white',
				},
			},
			elements: {
				center: {
					text: chartText,
					color: '#a9d6e5', // Default is #000000
					fontStyle: 'Helvetica', // Default is Arial
					sidePadding: 20, // Default is 20 (as a percentage)
					minFontSize: 25, // Default is 20 (in px), set to false and text will not wrap.
					lineHeight: 25, // Default is 25 (in px), used for when text wraps
				},
			},
		},
	};

	return (
		<>
			<Doughnut
				height='437px'
				data={config.data}
				labels={config.labels}
				options={config.options}
			/>
		</>
	);
}

export default RadialChart;
